import { Link } from 'components';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'm4-nurture-goal1',
    title: 'Spend some one-on-one time with your teenager.',
    content: (
      <>
        Pick an activity from the <Link href="/nurture/the-together-list">Together List</Link> to do with your teen.
      </>
    ),
  },
  {
    id: 'm4-nurture-goal2',
    title: 'With your teenager, plan a fun activity for the whole family.',
    content: (
      <>
        See <Link href="/nurture/ways-to-connect">Ways to connect better</Link>.
      </>
    ),
  },
  {
    id: 'm4-nurture-goal3',
    title: 'Think of a way your teen could be more involved in the household chores.',
    content: (
      <>
        Discuss this with them. Encourage them to take up a responsibility around the house. See{' '}
        <Link href="/nurture/responsibilities">What should my teenager be doing</Link>.
      </>
    ),
  },
  {
    id: 'm4-nurture-goal4',
    title: 'Discuss with your teen an extra-curricular activity that they might be interested in doing.',
    content: (
      <>
        Be curious and encourage them to think of activities they may enjoy doing. See{' '}
        <Link href="/nurture/other-activities">What else could my teenager be doing</Link>.
      </>
    ),
  },
];

export default goals;
