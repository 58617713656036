import { Button, Link, styled, Typography } from '@mui/material';
import { mustBeAge } from 'components/form';
import { QuestionItem, QuestionSection, validateByContext } from 'app/survey/questions';
import update from 'immutability-helper';
import { useState } from 'react';
import { Dialog } from 'components/Dialog';

const Article = styled('article')(({ theme }) => ({
  '& li': {
    ...theme.typography.body1,
  },
}));

export enum UserCategory {
  Parent = 0,
  Professional = 1,
  Other,
}

export const IntroVideoTranscript = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Dialog open={show} onClose={() => setShow(false)}>
        <Dialog.Title>Transcript</Dialog.Title>
        <Dialog.Content>
          <Typography variant="h5">Welcome to PiP!</Typography>
          <Typography>This information will cover:</Typography>
          <ul>
            <li>What PiP aims to do for you and your young person,</li>
            <li>What is involved in PiP, and</li>
            <li>How to navigate the online program.</li>
          </ul>
          <Typography fontWeight="bold" mt={2}>
            A note on language used in PiP:
          </Typography>
          <Typography>
            PiP uses the term “parent” to refer to any adult carer, not just biological parents. We use the terms
            “teenager” and “teen” to refer to a young person of high-school age. PiP is currently available in English.
          </Typography>
          <Typography fontWeight="bold" mt={2}>
            What can you gain from PiP?
          </Typography>
          <Typography>PiP has been created to empower parents or adult carers to:</Typography>
          <ul>
            <li>Support a young person experiencing anxiety or depression,</li>
            <li>Learn and apply parenting strategies to support their mental health.</li>
          </ul>
          <Typography fontWeight="bold" mt={2}>
            Each family is unique.
          </Typography>
          <Typography>
            The parenting strategies in PiP are based on decades of research, and are appropriate for most families.
            However, each family is unique and sometimes, there may be situations or experiences that are not fully
            addressed by these strategies.
          </Typography>
          <br />
          <Typography>
            You know your family best, so we encourage you to consider how you might adapt the suggestions to your
            situation, and to seek support from a health professional for your young person or yourself, if needed. You
            can connect with eheadspace online or via phone.
          </Typography>
          <Typography fontWeight="bold" mt={2}>
            What's involved in PiP?
          </Typography>
          <Typography>You can choose between two program pathways–a tailored pathway, or a brief pathway.</Typography>
          <br />
          <Typography>
            The <strong>tailored pathway</strong> includes:
          </Typography>
          <ul>
            <li>Reflection questions about your parenting and your young person’s mental health (15-20 mins),</li>
            <li>
              Personalised feedback about your young person’s mental health, your parenting strengths and areas for
              further development, and
            </li>
            <li>Weekly online modules recommended for you.</li>
          </ul>
          <Typography>
            Hundreds of Australian families have completed their personalised PiP program and benefited from it.
          </Typography>
          <br />
          <Typography>
            If you prefer to skip straight to the modules without completing the surveys or getting personalised
            feedback, you can instead choose the <strong>brief pathway</strong>.
          </Typography>
          <Typography fontWeight="bold" mt={2}>
            The PiP website features:
          </Typography>
          <ul>
            <li>Your personal dashboard, where you access the reflection questions, feedback, and modules.</li>
            <li>
              A new module focusing on a specific area of parenting will ‘unlock’ each week. You’ll receive an email
              when a module unlocks.
            </li>
            <li>If you wish to complete a module that is locked, you can unlock it by clicking the padlock icon. </li>
          </ul>
          <Typography fontWeight="bold" mt={2}>
            PiP modules and goals:
          </Typography>
          <ul>
            <li>
              You can complete the modules in your own time, on any device with internet access. Each module takes about
              15 to 25 minutes.
            </li>
            <li>Move through each module by reviewing the content and clicking the next button. </li>
            <li>To get back to the dashboard, go to the navigation bar and click the dashboard icon.</li>
            <li>At the end of each module, you will select a goal, to help you practise the strategies covered.</li>
            <li>Selected goals will be displayed on your dashboard, where you can mark them as complete.</li>
          </ul>
          <Typography fontWeight="bold" mt={2}>
            What next?
          </Typography>
          <ul>
            <li>Complete your profile,</li>
            <li>Choose your program,</li>
            <li>Get started!</li>
          </ul>
          <Typography>We hope PiP will make a positive difference for you and your young person!</Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={() => setShow(false)}>Close</Button>
        </Dialog.Actions>
      </Dialog>
      <Link onClick={() => setShow(true)} color="darkPrimary.main" sx={{ '&:hover': { cursor: 'pointer' } }}>
        Read transcript
      </Link>
    </>
  );
};

export const getProfileQuestions = (opts: { userCategory?: UserCategory }): QuestionSection[] => {
  const { userCategory } = opts;
  let questions: QuestionSection[] = [
    {
      key: 'aboutUser',
      title: 'About you',
      questions: [
        {
          // Branched question
          key: 'childRelationship',
          type: 'radio',
          label: 'Are you a parent or adult carer of a young person aged 12 to 18 years? *',
          options: ['Yes', 'No'],
          required: true,
        },
        {
          // If Yes
          key: 'childRelationshipType',
          type: 'select',
          title: 'What is your relationship to this young person?',
          label: 'Relationship',
          inputLabel: 'Please specify',
          cond: 'childRelationship == Yes',
          options: [
            'Mother',
            'Father',
            'Step-mother',
            'Step-father',
            'Grandmother',
            'Grandfather',
            'Guardian',
            { label: 'Other', value: '_input' },
          ],

          required: true,
        },
        {
          // If No
          key: 'noNote',
          type: 'paragraph',
          content:
            'Although PiP is designed for parents of 12 to 18 year-olds, you are welcome to continue with the' +
            ' program. To proceed, you’ll still need to answer a few questions. Please answer these as best you can.',
          variant: 'smallGrey',
          cond: 'childRelationship == No',
          required: false,
        },
        {
          // If No
          key: 'otherUserType',
          type: 'select',
          label: 'Why are you interested in this program? Are you a: ',
          inputLabel: 'Please specify',
          cond: 'childRelationship == No',
          options: [
            'Parent of a young person under 12 or over 18',
            'Health professional',
            'Educational professional',
            'Researcher',
            'Student',
            'Young person',
            'Other adult, not in a direct caring role',
            { label: 'Other', value: '_input' },
          ],
          required: true,
        },
        {
          key: 'otherLanguage',
          type: 'radio',
          size: 'medium',
          label: 'Do you speak a language other than English at home?',
          options: ['Yes', 'No'],
          required: false,
        },
        {
          key: 'familySituation',
          type: 'select',
          title: 'How would you describe your family situation?',
          label: 'Family situation',
          options: [
            'Child or children living with both parents/guardians',
            'Parents separated but both involved in care of child or children',
            'Parents separated but only myself involved in care of child or children',
            'Sole parent',
            'Other (e.g. child living with grandparents)',
          ],
          required: false,
        },
        {
          // Multiple selection
          key: 'howPiPwasFound',
          type: 'checkgroup',
          dropdown: true,
          title: 'How did you find out about the PiP program?',
          helperText: 'Please select as many as apply or add your own response by selecting “Other”.',
          options: [
            'My headspace account',
            'Recommended by an eheadspace clinician/staff (e.g. by phone or web chat)',
            'Recommended by a headspace centre clinician/staff',
            'Recommended by another health professional (e.g. GP, mental health professional)',
            'From Monash University',
            'Family member',
            'Friend',
            'Online advertisement',
            'Social media (e.g. facebook, instagram)',
            'PiP podcast',
            'Other',
          ],
          required: false,
        },
        {
          key: 'howPiPwasFound_other',
          type: 'text',
          cond: 'howPiPwasFound has Other',
          label: 'Please specify',
          showDisclaimer: true,
          required: false,
        },
        {
          key: 'inHSService',
          type: 'radio',
          label: 'Have you and/or your young person accessed services at a headspace centre before?',
          options: ['Yes', 'No'],
          required: false,
        },
        {
          key: 'onHSWaitlist',
          type: 'radio',
          label: 'Is your young person currently on a waitlist to be seen by a headspace centre?',
          options: ['Yes', 'No'],
          required: false,
        },
        {
          // Multiple selection
          key: 'reasonForParticipate',
          type: 'checkgroup',
          title:
            ' We are interested in your reasons for signing up for the PiP program and what you’re hoping to get from it.',
          helperText: 'Please select as many as apply or add your own response by selecting “Other”.',
          dropdown: true,
          options: [
            'To learn new parenting strategies or tips',
            'To learn about adolescent mental health and development',
            'To learn how I can support my young person’s mental health',
            'To learn how I can help prevent mental health problems in my young person',
            'I’m worried about my young person’s current mental health',
            'I think my young person is at risk of developing future mental health problems',
            'My young person has had mental health problems in the past',
            'We have a family history of mental health problems',
            'The program was recommended to me by someone else',
            'General interest in checking the program out',
            'The program sounds like it is easy to complete/will fit my lifestyle',
            'Other',
          ],
          required: false,
        },
        {
          key: 'reasonForParticipate_other',
          type: 'text',
          cond: 'reasonForParticipate has Other',
          label: 'Please specify',
          required: false,
          showDisclaimer: true,
        },
      ],
    },
    {
      key: 'aboutTeenager',
      title: 'About your young person',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content:
            'If you have multiple young people in your care, please answer based on the young person you are most interested in using the program strategies with.',
        },
        {
          key: 'childAge',
          type: 'text',
          size: 'medium',
          title: 'How old is the young person you are supporting?',
          label: "Young person's age",
          required: true,
          validate: validateByContext((context: any) =>
            context['childRelationship'] === 'No' &&
            context['otherUserType'] === 'Parent of a young person under 12 or over 18'
              ? mustBeAge(0, 99, 'Please enter a number from 0 to 99.')
              : mustBeAge(12, 25, 'Please enter a number from 12 to 25.'),
          ),
        },
        {
          key: 'childGenderIdentity',
          type: 'select',
          title: 'What is your young person’s gender identity?',
          label: "Young person's gender identity",
          options: [
            'Female',
            'Male',
            'Trans woman',
            'Trans man',
            'Gender fluid',
            'Gender questioning',
            'Agender',
            'Non-binary',
            'Sistergirl',
            'Brotherboy',
            //{ label: "My teenager's gender identity isn’t listed", value: '_input' },
          ],
          required: true,
          size: 'medium',
        },
        {
          // Multiple selection
          key: 'currentDifficulties',
          type: 'checkgroup',
          title: ' Is your young person currently experiencing any of the following difficulties? ',
          dropdown: true,
          helperText:
            'Please select as many as apply or add your own response by selecting “Other”. Remember, if you or your young person need more support, you can contact eheadspace or one of the services on our Useful Resources page (click ‘More support’ at the top of the page).',
          options: [
            'Mood problems (e.g. depression)',
            'Anxiety (including anxiety disorders)',
            'Problems with sleep',
            'Problems with eating or an eating disorder',
            'School refusal or difficulty attending school due to emotional distress',
            'Problems with alcohol or drugs',
            'Trauma-related problems (e.g. post-traumatic stress disorder)',
            'Obsessive compulsive problems',
            'Social or relationship difficulties (e.g. at home or at school)',
            'Bullying ',
            'Academic difficulties',
            'Attention problems (including ADHD)',
            'Autism Spectrum Disorder',
            'Developmental delay, disorder, or intellectual disability',
            'Language, communication, or learning difficulties',
            'Anger problems, oppositional defiance, or conduct problems',
            'Personality difficulties (e.g. personality disorder)',
            'Psychosis (e.g. hearing or seeing things that aren’t there)',
            { label: 'None of the above', value: 'None of the above', mutuallyExclusive: true },
            'Other',
          ],
          required: false,
        },
        {
          key: 'currentDifficulties_other',
          type: 'text',
          cond: 'currentDifficulties has Other',
          label: 'Please specify',
          required: false,
          showDisclaimer: true,
        },
      ],
    },
    {
      key: 'selectPathway',
      title: 'Choose your PiP program pathway',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography variant="body1" gutterBottom>
                You can choose between two pathways of the PiP program: A brief pathway, or a tailored pathway. The
                pathways are explained in the introduction video above—if you haven’t already, we suggest you watch the
                video or read the transcript to find out more about the two pathways.
              </Typography>
            </Article>
          ),
        },
        {
          key: 'userPathway',
          type: 'select',
          title: 'Choose your pathway (tailored or brief):',
          label: 'Pathway',
          options: [
            { label: 'Tailored pathway - I want to receive feedback and module recommendations', value: 'Tailored' },
            { label: 'Brief pathway - take me straight to the modules, I’ll choose my own', value: 'Non-tailored' },
          ],
          required: true,
        },
      ],
    },
  ];

  // extra text for eligible check
  if (userCategory) {
    questions = update(questions, {
      3: {
        questions: {
          $push: [
            {
              key: 'userCategoryEligible',
              type: 'paragraph',
              content:
                userCategory == UserCategory.Other ? (
                  <Typography variant="subtitle2" gutterBottom>
                    Please note that the PiP program is designed for parents of teenagers aged 12 to 17. As your child
                    is outside of this age group, you might find that some of the content is not relevant for you. You
                    are still welcome to do the program, but we ask that you keep this in mind. Also, because we are
                    conducting a research trial of the program at the moment, we won't include your data in our results.
                    We also won't invite you to join the online peer support group. Please feel free to contact us if
                    you have any questions.
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" gutterBottom>
                    Thanks for letting us know. As a professional, you're welcome to check out the online program for
                    your own interest. Your experience of the program will appear the same as it does for parents. Your
                    data will not be included in the associated research project. Please feel free to contact us if you
                    have any questions about the program.
                  </Typography>
                ),
            } as QuestionItem,
          ],
        },
      },
    });
  }

  return questions;
};
