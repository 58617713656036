import {
  Stack,
  SxProps,
  Typography,
  Theme,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { useAppSelector } from 'app/store';
import { BasicVideo } from 'app/topics/pip/BasicVideo';
import { Link } from 'components/Link';
import { useState } from 'react';
import { IntroVideoTranscript } from './getProfileQuestions';

export const ONBOARDING_VIDEO_URL = 'https://player.vimeo.com/video/843086917';

export function ProfileBlock(props: { sx?: SxProps<Theme> }) {
  const { user } = useAppSelector((state) => state.profile.data!);
  const [showIntroductoryVideo, setShowIntroductoryVideo] = useState(false);

  const handleIntroductoryVideoClick = () => {
    setShowIntroductoryVideo(true);
  };
  return (
    <Stack sx={props.sx}>
      <Dialog fullWidth maxWidth="md" open={showIntroductoryVideo} onClose={() => setShowIntroductoryVideo(false)}>
        <DialogTitle>Introductory Video</DialogTitle>
        <DialogContent>
          <BasicVideo light={false} sx={{ width: '100%' }} url={ONBOARDING_VIDEO_URL} />
          <IntroVideoTranscript />
        </DialogContent>
        <DialogActions>
          <Button color="darkPrimary" onClick={() => setShowIntroductoryVideo(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography gutterBottom variant="h5" fontWeight="bold" sx={{ textAlign: 'center' }}>
          Welcome{user.userFirstname ? `, ${user.userFirstname}` : ''}!
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', px: { xs: 0, md: 13 } }}>
          Welcome to Partners in Parenting (PiP)! Here on your program dashboard, you’ll have access to your modules and
          program reflection questions. If you want a refresher about the program at any stage, you can watch the{' '}
          <Link sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleIntroductoryVideoClick}>
            introductory video
          </Link>
          .
        </Typography>
      </Box>
    </Stack>
  );
}
