import { ReactNode, useMemo } from 'react';
import { navigate } from 'gatsby-link';
import { Grid } from '@mui/material';
import { Section } from 'components';
import { SurveyCard } from './SurveyCard';

import type { state, data } from 'app';

export type SurveyOverviewProps = {
  title: ReactNode;
  surveys: data.Survey[];
  userSurveys: Record<string, state.Survey>;
  completed: boolean;
  subheading?: ReactNode;
};

export function SurveyOverview(props: SurveyOverviewProps) {
  const { title, surveys, userSurveys, subheading } = props;
  const [_, entries] = useSurveyItems(surveys, userSurveys);

  return (
    <Section aria-label="user-overview" title={title}>
      {subheading}
      <Grid container spacing={4} role="grid" alignItems="stretch">
        {entries.map((entry, index) => {
          return (
            <Grid key={entry.key} item xs={12} sm={6} md={4} lg={3} sx={{ mb: '8px' }} role="gridcell">
              <SurveyCard
                id={entry.key}
                src={entry.cover?.localFile.publicURL}
                tint={entry.tint}
                title={entry.title}
                extraStyle={entry.extraStyle}
                progress={entry.progress}
                onClick={() => navigate(`/surveys/${entry.key}`)}
                transitionDelay={100 + 200 * index}
              />
            </Grid>
          );
        })}
      </Grid>
    </Section>
  );
}

export type SurveyItem = data.Survey & {
  progress: number | null;
};

const useSurveyItems = (surveys: data.Survey[], userSurveys: Record<string, state.Survey>) => {
  return useMemo(() => {
    const getProgress = (userSurvey: state.Survey | undefined, survey: data.Survey): number | null => {
      if (!userSurvey) return null;
      if (userSurvey._step === survey.sections.data.length) {
        return 1;
      }
      const { _total = 0, _completed = 0 } = userSurvey;
      return _total > 0 ? _completed / _total : 0;
    };

    // calculate survey section state
    return surveys.reduce<[number, SurveyItem[]]>(
      ([total, items], survey) => {
        const progress = getProgress(userSurveys[survey.key], survey);
        const item: SurveyItem = { ...survey, progress };
        return [total + (item.progress || 0), [...items, item]];
      },
      [0, []],
    );
  }, [surveys, userSurveys]);
};
