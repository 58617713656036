import { GoTo } from '../pip';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'understanding-goal1',
    title: (
      <>
        Check out the list of resources for parents and teens on our <GoTo to="/resources">Useful resources</GoTo> page.
      </>
    ),
    content: <>Take your time and find out more about these online resources.</>,
  },
  {
    id: 'understanding-goal2',
    title:
      "Think about what factors in your teen's life could be contributing to their depression or anxiety disorder.",
    content: (
      <>
        See{' '}
        <GoTo to="/understanding/risk-factors">
          What factors can contribute to depression and anxiety disorders in teeengaers?
        </GoTo>{' '}
        Have a think about what you might be able to do to reduce the impact of these factors on your teenager.
      </>
    ),
  },
  {
    id: 'understanding-goal3',
    title: 'Talk with your teen about professional mental health support.',
    content: (
      <>
        If your teenager is not seeing a mental health professional regularly, and you think they could benefit from
        seeing someone, set aside some time to talk with them about this. Offer to arrange for them to see a
        professional. See <GoTo to="/understanding/getting-worse">Things are getting - what should I do?</GoTo> and{' '}
        <GoTo to="/understanding/professional-support">Getting professional support</GoTo>.
      </>
    ),
  },
  {
    id: 'understanding-goal4',
    title: 'Encourage your teenager to try out one of the self-help strategies.',
    content: (
      <>
        Consider how you could help your teen to remember to do these things regularly. For example, setting a calendar
        reminder or sending them a reminder text on particular days or times. See{' '}
        <GoTo to="/understanding/self-help-depression">Self-help strategies for depression</GoTo> and{' '}
        <GoTo to="/understanding/self-help-anxiety">Self-help strategies for anxiety disorders</GoTo>.
      </>
    ),
  },
];

export default goals;
