import { Link } from 'components';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'm8-problems-goal1',
    title:
      'Talk with your teen about a problem they are experiencing and work with them to apply the 6 problem-solving steps.',
    content: (
      <>
        See Effective problem-solving: <Link href="/problems/problem-solving">1. Define the problem</Link>,{' '}
        <Link href="/problems/brainstorm-solutions">2. Brainstorm possible solutions</Link>,{' '}
        <Link href="/problems/evaluate-solutions">3. Evaluate the possible solutions</Link>,{' '}
        <Link href="/problems/decide-solution">4. Decide on a solution</Link>,{' '}
        <Link href="/problems/evaluate-outcome">5. Put the plan into action</Link> and{' '}
        <Link href="/problems/evaluate-outcome">6. Evaluate the outcome</Link>.
      </>
    ),
  },
  {
    id: 'm8-problems-goal2',
    title: 'Practise one stress management technique with your teenager.',
    content: (
      <>
        See <Link href="/problems/stress-management">Stress management skills</Link>.
      </>
    ),
  },
  {
    id: 'm8-problems-goal3',
    title:
      'Talk with your teen about the pressures they are under from school, sporting clubs, media, friends and family.',
    content: (
      <>
        Discuss with them how they are managing these pressures and how you can support them. See{' '}
        <Link href="/problems/pressures-expectations">Managing pressures and expectations</Link>.
      </>
    ),
  },
  {
    id: 'm8-problems-goal4',
    title:
      'Consider how you can adjust your expectations of your teen to reduce any excessive pressure they might feel.',
    content: (
      <>
        How well do these expectations match your teen’s personality and current capabilities? Have an open chat with
        your teen about your reflections. See{' '}
        <Link href="/problems/pressures-expectations">Managing pressures and expectations</Link>.
      </>
    ),
  },
];

export default goals;
