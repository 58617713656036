
import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Good friends = supportive relationships'
export const goals = [
    {
      id: 'm6-friends-goal1',
      title: 'Encourage your teen to do something kind for a friend or family member.',
      content: (
        <>
          See <Link href="/friends/good-social-skills">Good social skills</Link>.
        </>
      ),
    },
    {
      id: 'm6-friends-goal2',
      title: 'Encourage your teen to join a social activity that they might enjoy doing.',
      content: (
        <>
          See <Link href="/friends/social-situations">A range of social situations</Link>.
        </>
      ),
    },
    {
      id: 'm6-friends-goal3',
      title: 'Ask your teen if they would like to invite their friends over to your home.',
      content: (
        <>
          See <Link href="/friends/you-and-their-friends">You and your teenager’s friends</Link>.
        </>
      ),
    },
    {
      id: 'm6-friends-goal4',
      title: 'Take the opportunity to talk with your teenager if they are having friendship troubles.',
      content: (
        <>
          See <Link href="/friends/friendship-troubles">Friendship troubles</Link>.
        </>
      ),
    },
  ]