import connect from './connect';
import parentingInPandemic from './parentingInPandemic';
import familyRules from './familyRules';
import nurture from './nurture';
import conflict from './conflict';
import friends from './friends';
import healthHabits from './healthHabits';
import problems from './problems';
import anxiety from './anxiety';
import understanding from './understanding';
import understandingSchoolRefusal from './understandingSchoolRefusal';
import overcomingSchoolRefusal from './overcomingSchoolRefusal';

export type Goal = {
  id: string;
  title: React.ReactNode;
  content: React.ReactNode;
};

export const allGoals = [
  connect,
  parentingInPandemic,
  familyRules,
  nurture,
  conflict,
  friends,
  healthHabits,
  problems,
  anxiety,
  understanding,
  understandingSchoolRefusal,
  overcomingSchoolRefusal,
]
  .flat()
  .reduce<Record<string, Goal>>((goals, entry) => {
    return { ...goals, [entry.id]: entry };
  }, {});

export { GoalsBlock } from './GoalsBlock';
