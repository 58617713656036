import { useMemo } from 'react';
import { goals } from 'generated';
import { data, state } from 'app';

export type UserGoal = {
  topicId: string;
  goalId: string;
  topicLabel: string;
  title: React.ReactNode;
  content: React.ReactNode;
  completed: boolean;
};

type GroupedUserGoals = {
  completed: UserGoal[];
  todo: UserGoal[];
};

export function useGroupedGoals(topics: data.Topic[], userTopics: Record<string, Pick<state.Topic, 'goals'>>) {
  const topicTitles = useMemo(() => {
    return topics.reduce<Record<string, string>>((titles, topic) => ({ ...titles, [topic.key]: topic.title }), {});
  }, [topics]);

  return useMemo(() => {
    return Object.entries(userTopics).reduce<GroupedUserGoals>(
      (grouped, [topicId, userTopic]) => {
        const selectedGoals = userTopic.goals;
        const topicLabel = topicTitles[topicId];
        if (!selectedGoals || !topicLabel) {
          return grouped;
        }
        const goalData = goals.find((g) => g.title === topicLabel)?.goals;
        return Object.entries(selectedGoals).reduce<GroupedUserGoals>((grouped, [goalId, completionStatus]) => {
          const completed = completionStatus?.completed !== undefined;
          const data = goalData?.find((g) => g.id === goalId);
          const userGoal: UserGoal = {
            topicId,
            goalId,
            topicLabel,
            title: data?.title ?? `"${topicLabel}" Goal.`,
            content: data?.content ?? '',
            completed,
          };
          return completed
            ? { completed: [...grouped.completed, userGoal], todo: grouped.todo }
            : { completed: grouped.completed, todo: [...grouped.todo, userGoal] };
        }, grouped);
      },
      {
        completed: [],
        todo: [],
      },
    );
  }, [userTopics, topicTitles]);
}
