import { useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, ButtonBaseProps, Typography, useTheme } from '@mui/material';
import CheckedIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/ChevronRight';
import { Ring } from 'components/Ring';
import type { data } from 'app';

export type SurveyCardProps = {
  id: string;
  title: string;
  src: string;
  tint: string;
  extraStyle?: data.Survey['extraStyle'];
  progress: number | null;
  transitionDelay: number;
} & Pick<ButtonBaseProps, 'onClick'>;

export function SurveyCard(props: SurveyCardProps) {
  const { id, progress, transitionDelay, tint, src, title, extraStyle, onClick } = props;

  const theme = useTheme();

  const inner = useMemo(() => {
    switch (progress) {
      case 1:
        return <CheckedIcon />;
      case null:
        return <LockIcon />;
      default:
        return `${Math.round(progress * 100)}%`;
    }
  }, [progress]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '10rem',
      }}
      role="figure"
      aria-label={id}>
      <ButtonBase
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 1,
          overflow: 'hidden',
          display: 'flex',
          color: '#FFF',
          boxShadow: 1,
          bgcolor: tint,
        }}
        aria-label={id}
        onClick={onClick}
        focusRipple>
        <Cover src={src} extraStyle={extraStyle} transitionDelay={transitionDelay} />
        <Box
          component="section"
          sx={{
            flex: 3,
            width: '70%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 4,
            justifyContent: 'center',
            color: '#FFF',
            py: 3,
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography
              align="right"
              sx={{
                lineHeight: 1.2,
                overflow: 'hidden',
                whiteSpace: 'pre-wrap',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                marginRight: 3,
                boxOrient: 'vertical',
                textOverflow: 'ellipsis',
                fontSize: { xs: '1.2rem', md: '1rem', lg: '0.9rem', xl: '1rem' },
                color: theme.palette.getContrastText(tint),
              }}>
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              marginRight: 3,
            }}>
            <Ring percent={progress ?? 0} size="small" name={id}>
              {inner}
            </Ring>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}

const Cover = (props: {
  src: string;

  transitionDelay?: number;
  extraStyle?: data.Survey['extraStyle'];
}) => {
  const { src, transitionDelay = 0, extraStyle = {} } = props;
  const [slideIn, setSlideIn] = useState(false);
  useEffect(() => {
    setTimeout(() => setSlideIn(true), transitionDelay);
  }, []);

  return (
    <Box
      sx={(theme) => ({
        opacity: 1,
        width: '30%',

        '& > img': {
          display: 'block',
          width: '100%',
          transform: 'translateY(100%)',
          opacity: 0,
          transition: theme.transitions.create(['transform', 'opacity'], { duration: 1000 }),
          ...extraStyle,
          ...(slideIn && {
            transform: 'translateY(0)',
            opacity: 1,
          }),
        },
      })}>
      <img src={src} alt="cover" />
    </Box>
  );
};
