import { GoTo } from '../pip';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'understanding-school-refusal-goal1',
    title: 'Speak with your teen about school.',
    content: (
      <>
        If you haven’t spoken with your teen recently about going to school, set aside a time in the next week to have a
        conversation. Try to focus on understanding the current reasons for their school refusal rather than solving the
        problem for them. See{' '}
        <GoTo to="/understanding-school-refusal/talking">Talking with your teen about their school refusal</GoTo>.
      </>
    ),
  },
  {
    id: 'understanding-school-refusal-goal2',
    title: 'Arrange for your teen to see a mental health professional.',
    content: (
      <>
        If your teen is not seeing a mental health professional regularly and you think it may be helpful, arrange for
        them to see one. See{' '}
        <GoTo to="/understanding-school-refusal/professional-support">Seeking professional support</GoTo>.
      </>
    ),
  },
  {
    id: 'understanding-school-refusal-goal3',
    title:
      'Start keeping a record of the days your teen does and doesn’t attend school and what your teen is concerned about.',
    content: (
      <>
        See{' '}
        <GoTo to="/understanding-school-refusal/struggles">
          If your teen struggles to talk about their school refusal
        </GoTo>
        .
      </>
    ),
  },
  {
    id: 'understanding-school-refusal-goal4',
    title: 'Pick one self-care activity that you could do more of this week. ',
    content: (
      <>
        Think about when and how you can fit this into your schedule. See{' '}
        <GoTo to="/understanding-school-refusal/look-after-yourself">Look after yourself</GoTo>.
      </>
    ),
  },
];

export default goals;
