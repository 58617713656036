
import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Raising good kids into great adults'
export const goals = [
    {
      id: 'm3-family-rules-goal1',
      title: 'Set aside some time to discuss rules and consequences with your teenager.',
      content: (
        <>
          If you don’t have any clear rules or consequences for your teen’s behaviour, set aside some time to discuss
          this with them. See <Link href="/family-rules/how-to-rules">How?</Link>
        </>
      ),
    },
    {
      id: 'm3-family-rules-goal2',
      title: 'Review your existing rules and consequences.',
      content: (
        <>
          See if any of your existing rules or consequences need to be adjusted to give your teen more freedom. See{' '}
          <Link href="/family-rules/review-rules">Reviewing rules</Link>.
        </>
      ),
    },
    {
      id: 'm3-family-rules-goal3',
      title: 'Thank your teenager for their good behaviour.',
      content: (
        <>
          Tell them you appreciate it, and try to do something special for them. See{' '}
          <Link href="/family-rules/reward-good-behaviour">Catch 'em being good</Link>.
        </>
      ),
    },
    {
      id: 'm3-family-rules-goal4',
      title: 'Think of a rule for your teen’s behaviour that doesn’t have clear consequences.',
      content: (
        <>
          Together with your teenager, set some specific and realistic consequences. See{' '}
          <Link href="/family-rules/how-to-rules">How?</Link> and{' '}
          <Link href="/family-rules/apply-rules">Applying the rules</Link>.
        </>
      ),
    },
  ]