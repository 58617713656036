import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { startSurveyStage, useAppDispatch } from 'app/store';
import { Welcome } from 'components/Welcome';
import { SurveyOverview } from './SurveyOverview';
import cover from './survey_cover.png';

import { data, headings, state } from 'app';
import { useMemo } from 'react';
import { surveysForPathway } from '@pip-headspace/common';

export type BaselineBlockProps = {
  baseline: data.Survey[];
  user: state.User;
  userSurveys: Record<string, state.Survey>;
  sx?: SxProps<Theme>;
};

export function BaselineBlock(props: BaselineBlockProps) {
  const { baseline, user, userSurveys } = props;
  const dispatch = useAppDispatch();

  const handleStartSurvey = () => {
    dispatch(startSurveyStage('survey'));
  };

  const surveys = useMemo(() => {
    return surveysForPathway(user, baseline);
  }, [user, baseline]);

  const stage = user.currentStage ? 'entries' : 'welcome';
  return (
    <Box id="baseline" sx={props.sx}>
      {stage === 'welcome' ? (
        <Welcome img={cover} title={headings.baseline}>
          <Typography variant="subtitle1" color="textSecondary" align="justify" gutterBottom paragraph>
            Welcome to the PiP program! The first stage of the program includes an initial online assessment, during
            which you'll receive personalised feedback. After the surveys, you'll have access to your tailored program
            right away. Click the button to get started.
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleStartSurvey}>
            Start
          </Button>
        </Welcome>
      ) : (
        <SurveyOverview
          title={
            <Typography gutterBottom variant="h5" fontWeight="bold">
              {headings.baseline}
            </Typography>
          }
          subheading={
            <Typography sx={{ mb: 2 }}>
              The first stage of the program includes an optional self-reflection survey, during which you can receive
              personalised feedback. The questions ask about your approach to parenting, your confidence, and your young
              person’s mental health. Click the first survey to get started.
            </Typography>
          }
          surveys={surveys}
          userSurveys={userSurveys}
          completed={Boolean(user.surveyCompletedAt)}
        />
      )}
    </Box>
  );
}
