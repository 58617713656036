import { useMemo } from 'react';
import moment from 'moment';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { Welcome } from 'components/Welcome';
import { headings } from 'app';
import { startSurveyStage, useAppDispatch } from 'app/store';
import { SurveyOverview } from './SurveyOverview';
import cover from './followup_cover.svg';

import type { state, data } from 'app';
import { navigate } from 'gatsby';
import { nextSurveyInPathway } from './helpers';

export type FollowupBlockProps = {
  followup: data.Survey[];
  user: state.User;
  userSurveys: Record<string, state.Survey>;

  sx?: SxProps<Theme>;
};

export function FollowupBlock(props: FollowupBlockProps) {
  const { followup, user, userSurveys } = props;
  const dispatch = useAppDispatch();
  const { followupUnlockAt } = user;

  const timeRemaining = useMemo(() => {
    if (followupUnlockAt) {
      const unlockMoment = moment(followupUnlockAt.toDate());
      return unlockMoment.isAfter(Date.now()) && unlockMoment.fromNow();
    }
  }, [followupUnlockAt]);

  const handleStartSurvey = async () => {
    await dispatch(startSurveyStage('followup'));
    const firstFollowupSurvey = nextSurveyInPathway(user, undefined, 'followup');
    if (firstFollowupSurvey) {
      navigate(`/surveys/${firstFollowupSurvey}`);
    }
  };

  return (
    <Box id="followup" sx={props.sx}>
      {user.currentStage !== 'followup' ? (
        <Welcome img={cover} title={headings.followup}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
            gutterBottom
            paragraph>
            Many parents find it helpful to reflect on their parenting again after they've completed the program. About
            3-months after you start the program, we'll invite you to complete a follow-up survey which will provide you
            with personalised feedback again. The questions will ask about your parenting, parenting confidence, and
            your young person’s mental health. We’ll also ask for your feedback about the program to help us improve PiP
            in the future.
          </Typography>
          {followupUnlockAt && (
            <Button variant="contained" color="secondary" onClick={handleStartSurvey} disabled={Boolean(timeRemaining)}>
              {timeRemaining ? `Unlocks ${timeRemaining}` : 'Start'}
            </Button>
          )}
        </Welcome>
      ) : (
        <SurveyOverview
          title={headings.followup}
          surveys={followup}
          userSurveys={userSurveys}
          completed={Boolean(user.followupCompletedAt)}
        />
      )}
    </Box>
  );
}
