import { goals as goals0, topicTitle as title0 } from './goals/nurture'
import { goals as goals1, topicTitle as title1 } from './goals/understanding'
import { goals as goals2, topicTitle as title2 } from './goals/anxiety'
import { goals as goals3, topicTitle as title3 } from './goals/family-rules'
import { goals as goals4, topicTitle as title4 } from './goals/problems'
import { goals as goals5, topicTitle as title5 } from './goals/health-habits'
import { goals as goals6, topicTitle as title6 } from './goals/conflict'
import { goals as goals7, topicTitle as title7 } from './goals/connect'
import { goals as goals8, topicTitle as title8 } from './goals/friends'
export const goals = [
  { title: title0, goals: goals0 },
  { title: title1, goals: goals1 },
  { title: title2, goals: goals2 },
  { title: title3, goals: goals3 },
  { title: title4, goals: goals4 },
  { title: title5, goals: goals5 },
  { title: title6, goals: goals6 },
  { title: title7, goals: goals7 },
  { title: title8, goals: goals8 }
]