import { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Box } from '@mui/material';
import { useAppSelector } from 'app/store';
import { SitePage, siteToolbarPx } from 'app/layout';
import { TopicBlock } from 'app/topics';
import { ProfileBlock } from 'app/profile';
import { BaselineBlock, FollowupBlock } from 'app/survey';
import { contextualSurvey } from 'app/survey/helpers';
import { ensureAuth } from 'app/helpers';
import { useLocation } from '@reach/router';

import type { data } from 'app';
import { GoalsBlock } from 'app/topics/goals';
import { scrollToElement } from 'app/helpers/scrolling';

type DashboardProps = {
  data: {
    topics: {
      nodes: Array<data.Topic>;
    };
    baseline: {
      nodes: Array<data.Survey>;
    };
    followup: {
      nodes: Array<data.Survey>;
    };
  };
};

function DashboardPage(props: DashboardProps) {
  const location = useLocation();

  // Since the dashboard is rendered on the client, anchors in the URL
  // don't work natively. Here, we watch the current URL for an anchor
  // and scroll there if there is one
  useEffect(() => {
    if (location.hash) {
      setTimeout(() => scrollToElement(document.getElementById(window.location.hash.substring(1))), 100);
    }
  }, [location]);

  const {
    data: {
      topics: { nodes: topics },
      baseline: { nodes: baseline },
      followup: { nodes: followup },
    },
  } = props;

  const { user, topics: userTopics, surveys: userSurveys } = useAppSelector((state) => state.profile.data!);

  const [baselineData, followupData] = useMemo(() => {
    return [
      baseline.map((survey) => contextualSurvey(survey, user)),
      followup.map((survey) => contextualSurvey(survey, user)),
    ];
  }, [user]);

  return (
    <SitePage>
      <Box>
        <ProfileBlock
          sx={{
            px: siteToolbarPx,
            py: { xs: '2rem', sm: '42px' },
          }}
        />

        <BaselineBlock
          sx={{
            backgroundColor: 'primary.main',
            px: siteToolbarPx,
            py: { xs: '2rem', sm: '42px' },
          }}
          baseline={baselineData}
          user={user}
          userSurveys={userSurveys}
        />

        {user.currentStage !== 'survey' ? (
          <GoalsBlock
            topics={topics}
            userTopics={userTopics}
            sx={{
              px: siteToolbarPx,
              my: { xs: '2rem', sm: '42px' },
            }}
          />
        ) : null}

        <TopicBlock
          sx={{
            px: siteToolbarPx,
            my: { xs: '2rem', sm: '42px' },
          }}
          user={user}
          topics={topics}
          userTopics={userTopics}
          userSurveys={userSurveys}
          baselineSurveys={baselineData}
        />

        <FollowupBlock
          sx={{
            px: siteToolbarPx,
            my: { xs: '2rem', sm: '42px' },
          }}
          followup={followupData}
          user={user}
          userSurveys={userSurveys}
        />
      </Box>
    </SitePage>
  );
}

export default ensureAuth(DashboardPage);

export const query = graphql`
  query Dashboard($project: String = "pip-hs") {
    topics: allStrapiTopic(
      filter: { project: { name: { eq: $project } }, trainingOnly: { ne: true } }
      sort: { fields: seq, order: ASC }
    ) {
      nodes {
        ...DashboardTopicFragment
      }
    }
    baseline: allStrapiSurvey(
      filter: { project: { name: { eq: $project } }, scope: { eq: "baseline" } }
      sort: { fields: seq }
    ) {
      nodes {
        ...DashboardSurveyFragment
      }
    }
    followup: allStrapiSurvey(
      filter: { project: { name: { eq: $project } }, scope: { eq: "followup" } }
      sort: { fields: seq }
    ) {
      nodes {
        ...DashboardSurveyFragment
      }
    }
  }

  fragment DashboardTopicFragment on STRAPI_TOPIC {
    key: slug
    seq
    title
    description
    cover {
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.25, height: 800)
        }
      }
    }
    contents {
      key: slug
      seq
      title
      optional
      goals
      activityKeys {
        data: strapi_json_value
      }
    }
  }

  fragment DashboardSurveyFragment on STRAPI_SURVEY {
    key
    seq
    scope
    title
    tint
    cover {
      localFile {
        publicURL
      }
    }
    extraStyle {
      left
      bottom
      height
    }
    sections {
      data: strapi_json_value {
        key
        title
        type
        scales
        options
        required
        questions {
          key
          label
          type
          scales
          options
          answers
          variant
          content
          cond
          required
          title
        }
      }
    }
  }
`;
