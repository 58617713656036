import { Link } from 'components';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'm2-pandemic-goal1',
    title: 'Pick one self-care activity that you could do more of this week.',
    content: (
      <>
        Think about when and how you can fit this into your schedule. See{' '}
        <Link href="/parenting-in-pandemic/self-care">Prioritising self-care</Link>.
      </>
    ),
  },
  {
    id: 'm2-pandemic-goal2',
    title: 'Have a chat with your teen about what worries them most about the pandemic.',
    content: (
      <>
        Focus on listening and understanding their concerns. See{' '}
        <Link href="/parenting-in-pandemic/have-conversation">Have the conversations</Link> and{' '}
        <Link href="/parenting-in-pandemic/coping-worries">Coping with worries and uncertainties</Link>.
      </>
    ),
  },
  {
    id: 'm2-pandemic-goal3',
    title: 'Work with your teen to develop their ideal daily routine.',
    content: (
      <>
        Write this down and try to support your teen to stick to it for the week. See{' '}
        <Link href="/parenting-in-pandemic/routines">Creating healthy routines</Link>.
      </>
    ),
  },
  {
    id: 'm2-pandemic-goal4',
    title: 'Reflect on the expectations you have of your teenager.',
    content: (
      <>
        Think about whether any of these need adjusting during the pandemic. See{' '}
        <Link href="/parenting-in-pandemic/expectations">Managing your expectations</Link>.
      </>
    ),
  },
  {
    id: 'm2-pandemic-goal5',
    title: 'Have a chat with your teen about where they are getting the latest information about the pandemic.',
    content: (
      <>
        Discuss with your teen how reliable this information is. See the tips on{' '}
        <Link href="/parenting-in-pandemic/find-info">Finding the right information</Link>.
      </>
    ),
  },
];

export default goals;
