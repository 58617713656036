import { Link } from 'components';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'm1-connect-goal1',
    title: 'Try 3 suggestions to show more affection and encouragement to your teen.',
    content: (
      <>
        See suggestions from the <Link href="/connect/things-you-can-do">Things you can do</Link> and{' '}
        <Link href="/connect/things-you-can-say">Things you can say</Link> lists.
      </>
    ),
  },
  {
    id: 'm1-connect-goal2',
    title: 'Use identify, validate & understand techniques in conversations with your teenager.',
    content: (
      <>
        See <Link href="/connect/identify-validate-understand">Identify, validate & understand</Link>.
      </>
    ),
  },
  {
    id: 'm1-connect-goal3',
    title: 'Step back and look at how you initiate conversation with your teenager.',
    content: (
      <>
        Try one of the tips to start a conversation with your teen. See{' '}
        <Link href="/connect/take-time-to-talk">Take the time to talk</Link>,{' '}
        <Link href="/connect/conversation">Have the conversation</Link> and{' '}
        <Link href="/connect/talking-to-brickwall">It's like talking to a brick wall</Link>.
      </>
    ),
  },
  {
    id: 'm1-connect-goal4',
    title: 'Practise listening with your full attention in a conversation with your teenager.',
    content: (
      <>
        See <Link href="/connect/conversation">Have the conversation</Link>.
      </>
    ),
  },
];

export default goals;
