import { GoTo } from '../pip';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'overcoming-school-refusal-goal1',
    title: 'Arrange a time to meet with the school.',
    content: (
      <>
        If you haven’t spoken with your teen’s school lately, arrange a time to meet with the staff involved in
        supporting your teen. Think about what would be helpful to discuss in that meeting. See{' '}
        <GoTo to="/overcoming-school-refusal/meeting-staff">Meeting with school staff</GoTo>.
      </>
    ),
  },
  {
    id: 'overcoming-school-refusal-goal2',
    title: 'Develop a home routine.',
    content: (
      <>
        With your teen, develop a routine for school mornings or days at home that will support a calm, predictable, and
        structured environment. See{' '}
        <GoTo to="/overcoming-school-refusal/if-not-attending">If your teen is not currently attending school</GoTo> and{' '}
        <GoTo to="/overcoming-school-refusal/strategies">Strategies for school mornings</GoTo>.
      </>
    ),
  },
  {
    id: 'overcoming-school-refusal-goal3',
    title: 'Start or revise a return-to-school plan.',
    content: (
      <>
        With your teen, school staff and any professionals involved, start developing or revising a return-to-school
        plan for your teen. See{' '}
        <GoTo to="/overcoming-school-refusal/return-to-school-plan">Developing a return-to-school plan</GoTo>.
      </>
    ),
  },
  {
    id: 'overcoming-school-refusal-goal4',
    title: 'Understand a recent setback.',
    content: (
      <>
        If your teen has recently experienced a setback, take some time to understand where things may have broken down.
        Discuss with your teen how you can support them to continue to progress towards their goals. See{' '}
        <GoTo to="/overcoming-school-refusal/managing-setbacks">Managing setbacks</GoTo>.
      </>
    ),
  },
];

export default goals;
