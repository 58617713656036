import { Link } from 'components';
import type { Goal } from '.';

const goals: Goal[] = [
  {
    id: 'm7-health-habits-goal1',
    title:
      'Talk with your teen about new meals they would like to try. Pick an option with a variety of nutritious foods in it - cook it together, and enjoy!',
    content: (
      <>
        See <Link href="/health-habits/healthy-diet">Encourage a nutritious diet</Link>.
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal2',
    title: 'Have a conversation with your teenager about the types of social media they use.',
    content: (
      <>
        Discuss whether they are engaging in good-quality and helpful content. See{' '}
        <Link href="/health-habits/screentime">What about screen time?</Link>
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal3',
    title: 'Engage in some form of physical activity with your teenager.',
    content: (
      <>
        Help them to reach their goal of 60 minutes of physical activity a day. See Encourage physical activity{' '}
        <Link href="/health-habits/daily-exercise">Part I</Link> and{' '}
        <Link href="/health-habits/build-into-life">Part II</Link>).
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal4',
    title: 'Pick one of the good sleep habits and encourage your teen to try it out for one week.',
    content: (
      <>
        See <Link href="/health-habits/sleep-habits">Encourage good sleep habits</Link>.
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal5',
    title: 'Think about where you keep alcohol or cigarettes in your house.',
    content: (
      <>
        See if your teen might have access to these substances. See{' '}
        <Link href="/health-habits/no-alcohol-drugs">Discourage any alcohol and other drug use</Link>).
      </>
    ),
  },
];

export default goals;
