import { Paper, Typography, Box } from '@mui/material';

export type WelcomeProps = {
  title: string;
  img: string;
  children: React.ReactNode;
};

const height = { xs: 'auto', lg: '18rem', xl: '16rem' };

export function Welcome(props: WelcomeProps) {
  const { title, img, children } = props;

  return (
    <>
      <div id="topicCard" />
      <Paper
        sx={{
          my: 6,
          mx: 0,
          height,
          display: 'flex',
          flexWrap: 'wrap',
          overflow: 'hidden',
        }}
        role="banner"
        aria-label={title}
      >
        <Box
          sx={{
            minWidth: '20rem',
            overflow: 'hidden',
            display: {
              xs: 'none',
              lg: 'flex',
            },
            '& > img': {
              objectFit: 'contain',
              height,
              width: height,
            },
          }}
        >
          <img src={img} alt={title} />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: { xs: 'center', lg: 'flex-end' },
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: { xs: 2, sm: 4 },
            height: '100%',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ alignSelf: 'start', textAlign: { xs: 'center', lg: 'unset' }, width: '100%' }}
          >
            {title}
          </Typography>
          {children}
        </Box>
      </Paper>
    </>
  );
}
