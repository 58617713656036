
import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Breaking the anxiety cycle'
export const goals = [
    {
      id: 'm9-anxiety-goal1',
      title: 'Encourage your teenager to face their fears.',
      content: (
        <>
          Try the exposure strategy in{' '}
          <Link href="/anxiety/facing-fears">Fighting fear by facing fear</Link>.
        </>
      ),
    },
    {
      id: 'm9-anxiety-goal2',
      title: 'Look out for times when your teen tries to face one of their fears and praise them for their efforts.',
      content: (
        <>
          See <Link href="/anxiety/other-tips">Other ways to help your teenager manage anxiety</Link>.
        </>
      ),
    },
    {
      id: 'm9-anxiety-goal3',
      title: 'Model helpful anxiety management strategies for your teen.',
      content: (
        <>
          The next time you are confronted with an anxiety-provoking situation, show your teen how you actively use
          strategies to deal with your own anxieties (See{' '}
          <Link href="/anxiety/manage-own-anxiety">Help your teenager by managing your own anxiety</Link>).
        </>
      ),
    },
    {
      id: 'm9-anxiety-goal4',
      title: 'Think about the last time your teen became anxious about a situation and consider how you responded.',
      content: (
        <>
          Did you become anxious or stay calm? How would you respond differently next time? See{' '}
          <Link href="/anxiety/support">Support = Acceptance + Confidence</Link> and{' '}
          <Link href="/anxiety/manage-own-anxiety">Help your teenager by managing your own anxiety</Link>.
        </>
      ),
    },
  ]